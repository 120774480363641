import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as THREE from "three";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';
import { RGBMLoader } from 'three/examples/jsm/loaders/RGBMLoader.js';

class App extends Component {
  componentDidMount() {

    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera( 25, window.innerWidth/window.innerHeight, 0.1, 1000 );
    var renderer = new THREE.WebGLRenderer();
    let hdrCubeRenderTarget, hdrCubeMap

    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.antialias = true;
    renderer.setPixelRatio(2);
    renderer.outputEncoding = THREE.sRGBEncoding;
    document.body.appendChild( renderer.domElement );
    const controls = new OrbitControls( camera, renderer.domElement );
    controls.enableDamping = true
    controls.dampingFactor = 0.125
    controls.maxDistance = 50
    controls.minDistance = 1
    controls.minPolarAngle = 0.1
    controls.maxPolarAngle = 2
    controls.rotateSpeed = 0.5
    controls.autoRotate = true
    camera.position.set( -20.4, 10, 30.44 );

    var look_at = new THREE.Vector3( 0, 12, 0 );
    controls.target = look_at
    controls.update();



    const pmremGenerator = new THREE.PMREMGenerator( renderer );
    pmremGenerator.compileCubemapShader();
    var texture_loader = new THREE.TextureLoader();

    const hdrUrls = ['px.hdr', 'nx.hdr', 'py.hdr', 'ny.hdr', 'pz.hdr', 'nz.hdr'];
    hdrCubeMap = new HDRCubeTextureLoader()
        .setPath( 'textures/' )
        .load( hdrUrls, function () {
            hdrCubeRenderTarget = pmremGenerator.fromCubemap( hdrCubeMap );
            hdrCubeMap.magFilter = THREE.LinearFilter;
            hdrCubeMap.needsUpdate = true;
    } );
    var gold_normal = texture_loader.load("textures/engraving.jpg");

    let gold = new THREE.MeshStandardMaterial( {
        color: 0xff9b0b,
        normalMap: gold_normal,
        metalness: 1.0,
        roughness: 0.0,
        envMap: hdrCubeMap
    } );

    const loader = new OBJLoader();


    loader.load(
        'models/gold.obj',
        function (object) {
            object.traverse( function ( child ) {
                if ( child instanceof THREE.Mesh ) {
                    child.scale.set(.1,.1,.1)
                    child.material = gold;
            }});
            scene.add(object);
        }
    );
    var face_normal = texture_loader.load("textures/Mark_normal_texture.jpg");
    let gold_head = new THREE.MeshStandardMaterial( {
        color: 0xff9b0b,
        normalMap: face_normal,
        metalness: 1.0,
        roughness: 0.0,
        envMap: hdrCubeMap
    } );
    loader.load(
        'models/face.obj',
        function (object) {
            object.traverse( function ( child ) {
                if ( child instanceof THREE.Mesh ) {
                    child.scale.set(.1,.1,.1)
                    child.material = gold_head;
            }});
            scene.add(object);
        }
    );

    var ice_cream_texture = texture_loader.load("textures/ice_cream_diffuse.png");
    let ice_cream = new THREE.MeshStandardMaterial( {
        color: 0xffffff,
        map: ice_cream_texture,
        metalness: 0.0,
        roughness: 0.3,
        envMap: hdrCubeMap
    } );
    loader.load(
        'models/icecream.obj',
        function (object) {
            object.traverse( function ( child ) {
                if ( child instanceof THREE.Mesh ) {
                    child.scale.set(.1,.1,.1)
                    child.material = ice_cream;
            }});
            scene.add(object);
        }
    );

    var ball_texture = texture_loader.load("textures/basketball_a_albedo.jpg");
    var ball_normal = texture_loader.load("textures/basketball_a_normal.jpg");
    let ball = new THREE.MeshStandardMaterial( {
        emissive: 0xffffff,
        normalMap: ball_normal,
        color: 0x000000,
        emissiveMap: ball_texture,
        emissiveIntensity: 1.0,
        envMap: hdrCubeMap,
        metalness: 1.0,
        roughness: 0.33
    } );
    loader.load(
        'models/basketballs.obj',
        function (object) {
            object.traverse( function ( child ) {
                if ( child instanceof THREE.Mesh ) {
                    child.scale.set(.1,.1,.1)
                    child.material = ball;
            }});
            scene.add(object);
        }
    );


    loader.load(
        'models/glass.obj',
        function (object) {
            object.traverse( function ( child ) {
                if ( child instanceof THREE.Mesh ) {
                    child.scale.set(.1,.1,.1)
                    child.material = gold;
            }});
            scene.add(object);
        }
    );

    let wood = new THREE.MeshStandardMaterial( {
        color: 0x0f0700,
        metalness: 0.0,
        roughness: 0.45,
        envMap: hdrCubeMap
    } );

    loader.load(
        'models/wood.obj',
        function (object) {
            object.traverse( function ( child ) {
                if ( child instanceof THREE.Mesh ) {
                    child.scale.set(.1,.1,.1)
                    child.material = wood;
            }});
            scene.add(object);
        }
    );

    const light = new THREE.AmbientLight( 0x404040 ); // soft white light
    scene.add( light );
    scene.background = new THREE.Color( 0xffffff );

    window.addEventListener( 'resize', onWindowResize, false );
    function onWindowResize(){
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize( window.innerWidth, window.innerHeight );
    }

    var animate = function () {
      requestAnimationFrame( animate );
      controls.update();
      renderer.render( scene, camera );
    };
    animate();
  }



  render() {
    return (
      <div>
      </div>
    )
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);